const config = {
    apiEndpoint: `${process.env.NEXT_PUBLIC_API_ENDPOINT}`,
    openSeaApi: `${process.env.NEXT_PUBLIC_OPENSEA_API}`,
    etherscanTxUrl: `${process.env.NEXT_PUBLIC_ETHERSCAN_TX_URL}`,

    ethNetwork: `${process.env.NEXT_PUBLIC_ETHEREUM_NETWORK_ID}`,
    infuraId: `${process.env.NEXT_PUBLIC_INFURA_ID}`,
    rpcUrl1: `${process.env.NEXT_PUBLIC_RPC_URL_MAINNET}`,
    rpcUrl4: `${process.env.NEXT_PUBLIC_RPC_URL_RINKEBY}`,
    rpcUrl22: `${process.env.NEXT_PUBLIC_RPC_URL_FORK}`,
    rpcUrls: () => {
        const envIds = process.env.NEXT_PUBLIC_WALLET_CONNECTOR_IDS
        let ids: { [key: string]: string } = {}
        if (envIds) {
            envIds.split(',').map((item) => {
                switch (item) {
                    case '1':
                        ids[1] = `${process.env.NEXT_PUBLIC_RPC_URL_MAINNET}`
                        break
                    case '4':
                        ids[4] = `${process.env.NEXT_PUBLIC_RPC_URL_RINKEBY}`
                        break
                    case '22':
                        ids[22] = `${process.env.NEXT_PUBLIC_RPC_URL_FORK}`
                        break

                    default:
                        break
                }
            })
        }
        else ids[1] = `${process.env.NEXT_PUBLIC_RPC_URL_MAINNET}`
        return ids
    },
    torusChainId: `${process.env.NEXT_PUBLIC_TORUS_ID}` ?? '1',
    injectedChainIds: () => {
        const envIds = `${process.env.NEXT_PUBLIC_INJECTED_IDS}`
        if (envIds) return envIds.split(',').map((item) => +item)
        else return [1]
    },

    CACHE_TIME: process.env.NEXT_PUBLIC_CACHE_TIME,

    erc20ABI: [
        {
            constant: false,
            inputs: [
                { name: '_spender', type: 'address' },
                { name: '_value', type: 'uint256' },
            ],
            name: 'approve',
            outputs: [{ name: 'success', type: 'bool' }],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                { name: '_to', type: 'address' },
                { name: '_value', type: 'uint256' },
            ],
            name: 'showMeTheMoney',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                { name: '_to', type: 'address' },
                { name: '_value', type: 'uint256' },
            ],
            name: 'transfer',
            outputs: [{ name: 'success', type: 'bool' }],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                { name: '_from', type: 'address' },
                { name: '_to', type: 'address' },
                { name: '_value', type: 'uint256' },
            ],
            name: 'transferFrom',
            outputs: [{ name: 'success', type: 'bool' }],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: '_from', type: 'address' },
                { indexed: true, name: '_to', type: 'address' },
                { indexed: false, name: '_value', type: 'uint256' },
            ],
            name: 'Transfer',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: '_owner', type: 'address' },
                { indexed: true, name: '_spender', type: 'address' },
                { indexed: false, name: '_value', type: 'uint256' },
            ],
            name: 'Approval',
            type: 'event',
        },
        {
            constant: true,
            inputs: [
                { name: '_owner', type: 'address' },
                { name: '_spender', type: 'address' },
            ],
            name: 'allowance',
            outputs: [{ name: 'remaining', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [{ name: '_owner', type: 'address' }],
            name: 'balanceOf',
            outputs: [{ name: 'balance', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'decimals',
            outputs: [{ name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'name',
            outputs: [{ name: '', type: 'string' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'symbol',
            outputs: [{ name: '', type: 'string' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'totalSupply',
            outputs: [{ name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
    ],
}

export default config

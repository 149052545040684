import { DataForSend } from './../components/ClaimForm/typing'
import config from '../config/config'
import { nftStakes } from '../web3/web3.config'
const endpoint = config.apiEndpoint
const openSeaApi = config.openSeaApi

export const getEntity = async (entity: string, id?: number | null, params?: string) => {
    const resp = await fetch(`${endpoint}/${entity}${id ? `/${id}` : ''}${params ? params : ''}`)
    if (resp.ok) {
        const data = await resp.json()
        return data
    } else {
        const error = await resp.json()
        throw Error(error)
    }
}

export const getListEntitys = async (entity: string) => {
    const resp = await fetch(`${endpoint}/${entity}`)
    if (resp.ok) {
        const data = await resp.json()
        return data
    } else return false
}

export const contactUs = async (data: string) => {
    const resp = await fetch(`${endpoint}/contactus/start`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: data,
    })
    if (resp.ok) {
        const data = await resp.json()
        return data
    } else return await resp.json()
}

export const isAuctionTokenClaimed = async (data: { claimedBy: string; auctionId: number }) => {
    try {
        const resp = await fetch(
            `${endpoint}/nftshipping/isAuctionTokenClaimed?auctionId=${data.auctionId}&claimedBy=${data.claimedBy}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        if (resp.ok) {
            const result = await resp.json()
            return { error: false, data: result }
        } else {
            const error = await resp.json()
            throw new Error(error)
        }
    } catch (e) {
        return { error: true, message: e.message }
    }
}

export const getOpenseaData = async (address: string) => {
    const contractAddres = nftStakes[0].nftAddress
    const request = await fetch(`${openSeaApi}/assets?owner=${address}&asset_contract_addresses=${contractAddres}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        // Vary: 'Accept',
    })
    if (request.ok) {
        return await request.json()
    } else return false
}

export const claimAuctionToken = async (data: DataForSend) => {
    try {
        const resp = await fetch(`${endpoint}/nftshipping/claimauctiontoken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })

        if (resp.ok) {
            const result = await resp.json()
            return result
        } else {
            const error = await resp.json()
            throw Error(error)
        }
    } catch (e) {
        return { error: true, message: e.message }
    }
}
